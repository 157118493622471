import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            category: {
                $containsi: '',
            },
            $or: [
                {
                    question: {
                        $containsi: '',
                    },
                },
                {
                    answer: {
                        $containsi: '',
                    },
                },
            ],
        },
        pagination: {
            page: 1,
            pageSize: 5,
        },
    };
};

export default {
    namespaced: true,
    state: {
        faqs: [],
        query: initialQuery(),
        isDeleteFaq: false,
    },
    getters: {
        getFaqList(state) {
            return state.faqs;
        },
        getFaqQuery(state) {
            return state.query;
        },
        getFaq(state) {
            return state.faqs;
        },
        getIsDeleteFaq(state) {
            return state.isDeleteFaq;
        },
    },
    mutations: {
        setFaqList(state, faqs) {
            state.faqs = faqs;
        },
        setFaqQuery(state, query) {
            state.query = query;
        },
        resetFaqQuery(state) {
            state.query = initialQuery();
        },
        setFaq(state, faqData) {
            state.faqData = faqData;
        },
        setFaqSearch(state, search) {
            state.query.filters.$or[0].question.$containsi = search;
            state.query.filters.$or[1].answer.$containsi = search;
        },
        setIsDeleteFaq(state, bol) {
            state.isDeleteFaq = bol;
        },
    },
    actions: {
        fetchFaqList(ctx) {
            let _query = _.cloneDeep(ctx.state.query);

            let query = qs.stringify(_query);
            return new Promise((resolve, reject) => {
                axios
                    .get('/faqs?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchFaq(ctx, id, query = {}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/faqs/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateFaq(ctx, data) {
            let query = qs.stringify({});
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                delete _data.id;
                axios
                    .put(`/faqs/${id}?${query}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addFaq(ctx, faqData) {
            faqData = _.assign({}, faqData);

            return new Promise((resolve, reject) => {
                axios
                    .post('/faqs', { data: faqData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteFaq(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/faqs/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteManyFaqs(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/faqs/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        /*         
export function deleteManyItems(query = {}) {
    query = qs.stringify(query);
    return axios.delete(`/items/many?${query}`);
} */
        /*   addSections(ctx, sectionsData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/parking-lots-sections', { data: sectionsData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
